<template>
  <v-card elevation="0">
    <v-form ref="form">
      <v-card-title>{{ selectedData.id == '' ? 'Tambah' : 'Ubah' }} Data</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="selectedData.username"
              label="Username"
              placeholder="Username"
              outlined
              hide-details="auto"
              required
              :rules="[v => !!v || 'Username harus diisi!']"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="selectedData.password"
              label="Password"
              placeholder="Password"
              outlined
              type="password"
              hide-details="auto"
              required
              :rules="selectedData.id != '' ? [] : [v => !!v || 'Password harus diisi!']"
              dense
            ></v-text-field>
            {{ selectedData.id != '' ? 'Password diisi jika terjadi perubahan!' : '' }}
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="selectedData.name"
              label="Nama"
              placeholder="Nama"
              outlined
              hide-details="auto"
              required
              :rules="[v => !!v || 'Nama harus diisi!']"
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="selectedData.role_id"
              label="Role"
              placeholder="Role"
              outlined
              hide-details="auto"
              :items="roles"
              item-text="name"
              dense
              item-value="id"
              :rules="[v => !!v || 'Role harus diisi!']"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="selectedData.status"
              label="Status"
              placeholder="Status"
              outlined
              hide-details="auto"
              :items="statuses"
              item-text="name"
              dense
              item-value="value"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" small depressed @click="validate" :loading="loadingButton" :disabled="loadingButton"
              >Simpan</v-btn
            >
            <v-btn color="primary" small depressed outlined class="ml-3" @click="closeNav">Batal</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
export default {
  props: ['closeNav', 'getData', 'setAlert', 'selectedData', 'roles'],
  data() {
    return {
      statuses: [
        { name: 'Aktif', value: 1 },
        { name: 'Tidak Aktif', value: 0 },
      ],
      loadingButton: false,
    }
  },
  methods: {
    clearForm() {
      this.$refs.form.resetValidation()
    },
    validate() {
      if (this.$refs.form) {
        if (this.$refs.form.validate()) {
          this.submit()
        }
      }
    },
    submit() {
      this.loadingButton = true
      const token = localStorage.getItem('token')
      if (this.selectedData.id != '') {
        // edit
        axios
          .patch(
            `${process.env.VUE_APP_API_ENDPOINT}users/${this.selectedData.id}`,
            {
              username: this.selectedData.username,
              password: this.selectedData.password,
              name: this.selectedData.name,
              role_id: this.selectedData.role_id,
              status: this.selectedData.status,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            this.loadingButton = false
            if (res.status == 200) {
              this.setAlert({
                status: true,
                success: res.data.success,
                message: res.data.message,
              })
            } else {
              this.setAlert({
                status: true,
                success: false,
                message: res.statusText,
              })
            }
            this.getData()
            this.closeNav()
          })
          .catch(err => {
            this.loadingButton = false
            this.closeNav()
            const response = err.response
            const data = response.data
            this.setAlert({
              status: true,
              success: false,
              message: data.message,
            })
            if (data.message == 'Anda tidak diberikan akses!') {
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              this.$router.push({ name: 'login' })
            }
          })
      } else {
        axios
          .post(
            `${process.env.VUE_APP_API_ENDPOINT}users`,
            {
              username: this.selectedData.username,
              password: this.selectedData.password,
              name: this.selectedData.name,
              role_id: this.selectedData.role_id,
              status: this.selectedData.status,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then(res => {
            this.loadingButton = false
            if (res.status == 200) {
              this.setAlert({
                status: true,
                success: res.data.success,
                message: res.data.message,
              })
            } else {
              this.setAlert({
                status: true,
                success: false,
                message: res.statusText,
              })
            }
            this.getData()
            this.closeNav()
          })
          .catch(err => {
            this.loadingButton = false
            this.closeNav()
            const response = err.response
            const data = response.data
            this.setAlert({
              status: true,
              success: false,
              message: data.message,
            })
            if (data.message == 'Anda tidak diberikan akses!') {
              localStorage.removeItem('token')
              localStorage.removeItem('user')
              this.$router.push({ name: 'login' })
            }
          })
      }
    },
  },
}
</script>

<style>
</style>